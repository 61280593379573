.AppointmentNoteRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 13px;
  min-height: 44px;
  &:not(:last-child) {
    border-bottom: 1px solid #e2e2e3;
  }

  .note-details-info-container {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    .icon-btn-container {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      div {
        display: inline-block;
      }
    }

    .appointment-note-label {
      font-size: 11px;
      border: 1px solid #e2e2e3;
      border-radius: 4px;
      padding: 0px 5px;
    }

    .appointment-note-text {
      margin-right: 10px;
      width: 100%;
      cursor: pointer;
      font: normal normal normal 13px / 15px Lato;
      letter-spacing: 0px;
      color: #000000CC;
    }
  }

  .note-user-date-info-container {
    font-size: 12.6px;
    font-weight: 600;
    white-space: nowrap;

    .note-user-date-info {
      .user-name {
        font-style: italic;
        margin-right: 5px;
      }
    }
  }
}
