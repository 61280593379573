.CustomerCommunicationInterventions {
  &-section {
    border: 1px solid #e2e2e3;
    border-radius: 4px;
    margin-top: 14px;
    p {
      margin: 0;
    }
    &-group {
      &:not(:first-child) {
        border-top: 1px solid #e2e2e3;
      }
      &:empty + .CustomerCommunicationModal-section-group {
        border: none;
      }
      &:empty {
        display: none;
      }
      &_header {
        background-color: #f9fafb;
        border-bottom: 1px solid #e2e2e3;
        display: flex;
        align-items: center;
        padding: 0 14px;
        height: 36px;
        gap: 5px;
        p {
          font-size: 14px;
          font-weight: bold;
        }
        i {
          height: auto;
        }
      }
      .Intervention {
        border-bottom: 1px solid #e2e2e3;
        padding: 0 14px;
        .ui.label {
          height: 25px;
        }
        .ui.grid {
          .column:first-child {
            padding-left: 12px;
          }
        }
        .title-container {
          display: flex;
          align-items: center;
          gap: 10px;
          min-height: 44px;
          p {
            font-size: 12.6px;
            font-weight: bold;
            display: inline-block;
            margin-left: 5px;
          }
          i {
            font-size: 10px;
            &:not(:last-of-type) {
              margin-right: 10px;
            }
          }
          .title-container-button {
            cursor: default;
          }
        }
        .column-container {
          display: flex;
          align-items: center;
          height: 44px;
          .ui.label {
            cursor: pointer;
          }
          .ui.label.green {
            background-color: #48913d;
          }
          .ui.label.blue {
            background-color: #5279d1;
          }
          .ui.label.red {
            background-color: #ee3113;
          }
          .ui.label.teal {
            background-color: #02b5ad;
          }
          .ui.label.skyblue {
            background-color: #21a1d0;
            color: #fff;
          }
        }
        .column-container.gap {
          gap: 6px;
          margin-right: 20px;
        }
        .column-container.float-right {
          justify-content: flex-end;
        }
        .question-status-container {
          height: 100%;
          width: 32px;
          border: 1px solid #e2e2e3;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          .view-only i.icon {
            position: relative;
            left: 1px;
            font-size: 13px;
          }
          .visible.menu.transition {
            margin-top: 2px;
            > .item {
              border-top: 1px solid #e2e2e3;
              border-bottom: 1px solid #e2e2e3;
              padding: 10px !important;
              > i.icon {
                margin-right: 0;
                font-size: 12px;
              }
              &:first-child {
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
                border-top: none;
              }
              &:last-child {
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
                border-bottom: none;
              }
            }
          }
          .status-dropdown {
            > div > i.icon {
              font-size: 12px;
              position: relative;
              left: 3px;
              &.caret.down {
                font-size: 6px;
                position: relative;
                top: -2px;
              }
            }
          }
        }
        .status-container {
          height: 27px;
          border: 1px solid #e2e2e3;
          border-radius: 4px;
          display: flex;
          align-items: center;
          i.icon {
            margin: 0px;
            font-size: small;
            &.pointer {
              cursor: pointer;
            }
          }
          div {
            height: 100%;
            border-right: 1px solid #e2e2e3;
            width: 32px;
            display: flex;
            justify-content: center;
            align-items: center;

            i.icon {
              cursor: pointer;
            }

            &:last-child {
              border-right: none;
            }
          }
        }
        .tabs {
          .tab {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
          }
          .tab.green.active {
            color: #21ba45;
          }
          .tab.darkgreen.active {
            color: #48913d;
          }
          .tab.red.active {
            color: #ee3113;
          }
          .tab.blue.active {
            color: #5279d1;
          }
          .tab.teal.active {
            color: #02b5ad;
          }
          .tab.skyblue.active {
            color: #21a1d0;
          }
          i.icon.xmark {
            color: #000000cc;
          }
          .ui.tabular.menu {
            height: 36px;
            border-bottom: none;
            margin-bottom: 0px;
          }
        }
        .tabs-container {
          min-height: 50px;
          border: 1px solid #e2e2e3;
          border-radius: 4px;
          margin-bottom: 10px;
          padding: 10px 0;
          .ui.form {
            padding: 0 13.5px;
            .ui.grid {
              margin-top: 0;
            }
          }
          .ui.labeled.input > .label:not(.corner) {
            height: 38px;
            background-color: #f9fafb;
          }
        }
        .nodata {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }
      .Intervention:last-child {
        border-bottom: none;
      }
      .Interventions {
        .Interventions-container {
          border: 1px solid #e2e2e3;
          border-radius: 4px;
          margin-top: 14px;
        }
      }
      .InterventionModal {
        .ui.buttons.withdropdown {
          background-color: #f9fafb;
          border-radius: 4px;
          border: 1px solid #dededf;
          .button {
            padding: 5px 10px 5px 5px;
            background-color: inherit;
          }
          .icon.button {
            margin-top: 5px;
          }
        }
        .actions {
          justify-content: flex-end !important;
        }
        .button.customer {
          position: relative;
          bottom: 1px;
          height: 38px;
        }
        .ui.form .fields {
          display: flex;
          justify-content: space-between;
          padding: 0 7px;
          gap: 20px;
        }
      }
    }
  }
  .total-amount-container {
    margin-top: 15.7px;
    border: 1px solid #a8c599;
    border-radius: 4px;
    background-color: #fcfff5;
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 10px;
    justify-content: flex-end !important;
    p {
      font-size: 14px;
      color: #1e561fcc;
      display: inline-flex;
      align-items: center;
      gap: 10px;
      strong {
        font-size: 18px;
        color: #1e561f;
        font-weight: bold;
      }
    }
  }
}

.mechanic-fixed-status-container {
  position: relative;

  .wrench-check-icon {
    font-size: 8px;
    position: absolute;
    right: -1px !important;
    bottom: -2px !important;
  }
}
