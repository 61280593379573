.AppointmentActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 76px;
  align-items: center;
  margin: 0 10px;

  &-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > button.button {
      height: 36px;
      margin-left: 20px;
      color: #000000;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;

      i.icon {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

.AppointmentsTableContainer {
  position: relative;
  padding-bottom: 2em;
  min-height: calc(100vh - 118px);
  margin: 0 10px;

  .AppointmentsTableContainer-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

i.icon.arrow.down.a.z:before {
  content: "\f15d" !important;
}
i.icon.arrow.up.a.z:before {
  content: "\f15e" !important;
}
