.ExportDataModal {
  .export-file-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 24px;
  }

  .export-modal-download-button-container {
    display: flex;
    gap: 16px;
  }

  .export-modal-download-button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 11px !important;
    background-color: #e0e1e2 !important;
  }

  .export-modal-close-button-container {
    margin: 1px 1px 0px 5px !important;
  }

  .export-modal-close-button {
    height: 36px !important;
    width: 36px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .export-modal-download-text {
    color: #414141 !important;
    font-weight: 500 !important;
  }

  .export-modal-content-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 19px 0px;
    font-size: 17px;
  }
}
