.MenuView {
  &.ui.vertical.menu {
    width: 250px;
    background-color: #f9fafb;
    .item {
      padding: 0 8px;
      justify-content: space-between;
      align-items: center;
      color: #767676 !important;
      font-size: 12px;
      text-transform: capitalize;
      flex-direction: row;
      cursor: pointer;
      .icon {
        margin: 0 0.75rem 0 0 !important;
        padding: 0 !important;
        font-size: 1em !important;
        &:not(.dropdown) {
          margin: 0 0.75rem 0 0 !important;
          padding: 0 !important;
          font-size: 1em !important;
        }
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.05) !important;
      }
    }
    .item.menu-view-item {
      display: flex;
      padding: 0.92857143em 1.14285714em;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      color: #000000cc !important;
      border-bottom: 1px solid rgba(34, 36, 38, 0.15);
      padding-left: 0.5em;
      &.heading {
        background-color: rgba(0, 0, 0, 0.05) !important;
        color: #767676 !important;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        text-transform: uppercase;
        .icon {
          margin: 0 0 0 0.75rem !important;
          padding: 0 !important;
          font-size: 1em !important;
          &:not(.dropdown) {
            margin: 0 0 0 0.75rem !important;
            padding: 0 !important;
            font-size: 1em !important;
          }
        }
      }
    }
  }
}
