.ui.tiny.modal.transition.visible.active.CustomConfirm {
  .header {
    color: #fff;
  }
  .header.danger {
    background-color: #ff0202;
  }
  .header.warning {
    background-color: #ffba39;
  }
  .content {
    color: #000000cc;
    font-size: 16px;
    text-align: center;
    div.error {
      margin-top: 10px;
      color: #ff0202;
    }
    .CustomConfirm-section {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    &.danger {
      form {
        margin-top: 10px;
        .ui.selection.dropdown {
          width: 100%;
        }
      }
    }
  }
  .actions {
    justify-content: flex-end;
    .danger,
    .warning {
      color: #fff;
    }
    .danger {
      background-color: #ff0202;
    }
    .warning {
      background-color: #ffba39;
    }
  }
}
