.TyreReplacementModal {
  .modal-content-container {
    height: 70vh;
  }

  .in-stock-form-field {
    text-align: center;
  }

  .in-stock-checkbox {
    margin-top: 7px;
    text-align: "center";
  }

  .lower-customer-price {
    background-color: #fef0e7;
    color: #ff0202;
    width: fit-content;
  }

  .fields {
    margin: 0 !important;
    .no-padding-right {
      padding-right: 0 !important;
    }
  }

  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  .ui.grid {
    border: 1px solid #e2e2e3;
    margin-top: 30px;
  }

  .ui.grid > .row {
    padding-top: 0;
    padding-bottom: 0;
    height: 42px;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      border-bottom: 1px solid #e2e2e3;
    }
    &.header {
      background-color: #f9fafb;
      height: 36px;
    }
  }

  .search-button {
    color: #414141;
  }

  .search-form-label {
    visibility: hidden;
  }

  .replacement-tyre-ean {
    display: inline-block;
    width: 50%;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }

  .no-results-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
  }

  .no-results-text {
    height: 70px;
    font-size: 14px;
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 55px;
  }
}
