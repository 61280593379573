.ui.modal.RDWDetails {
  .header.RDWDetailsHeading {
    display: flex;
    justify-content: space-between;
    padding: 25px !important;
    .controls {
      display: flex;
      align-items: center;
      .icon-container {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e2e2e3;
        text-align: center;
        line-height: 18px;
        border-radius: 4px;
        cursor: pointer;
        i {
          font-size: 16px;
          margin: 0px;
          margin-bottom: 2px;
        }
      }
      .icon-container:first-child {
        margin-right: 5px;
      }
    }
  }
  .RDWDetailsContent.content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    div {
      display: flex;
      padding: 10px 0px;
      border-bottom: 1px solid #e2e2e3;
      span:first-child {
        font-weight: 600;
      }
      span:nth-child(2) {
        margin-left: 1em;
        margin-right: 1em;
      }
    }
    div:last-child {
      border-bottom: none;
    }
  }
}
