.PinModal {
  .modal-header {
    p {
      margin: 0;
      font-size: 18px;
      i.icon {
        margin-right: 10px;
      }
    }

    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px 0 0;
  }
  .modal-footer {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }
  .grid {
    margin-top: 0px !important;
  }
}
