.Tyre {
    .TyreCheckList {
        .TyreHeader {
            width: 100%;
            background-color: #f9fafb;
            height: 36px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            border-bottom: 1px solid #e2e2e3;
            p {
                font-size: 14px;
                font-weight: bold;
                width: 100%;
            }
        }
        .TyreTable {
            margin-bottom: 12px;
            &:not(:first-child) {
                border-top: 1px solid #e2e2e3;
            }
            .ui.grid {
                margin-left: 0;
                margin-right: 0;
            }
            .button.af {
                height: 25px;
                i.icon {
                    font-size: 14px;
                }
            }
            .media-row {
                height: fit-content !important;
                .checklist-media {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    flex-wrap: wrap;
                    padding-bottom: 10px;
                }
            }
            .row {
                padding: 0px 0px 0px 7px;
                height: 36px;
                border-left: none !important;
                border-right: none !important;
                border-bottom: 1px solid #e2e2e3;
                align-items: center;
                &:last-child {
                    border-bottom: none;
                }
                .column {
                    padding-left: 5px;
                    padding-right: 5px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    P {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .tyre-season-container {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                    }
                }
            }
            .header {
                background-color: #f9fafb;
                border: 1px solid #e2e2e3;
                p {
                    font-size: 14px;
                    font-weight: bold;
                }
            }
            .axle-header {
                height: 30px;
                background-color: #f2f2f2;
                p {
                    font-size: 14px;
                    font-weight: bold;
                }
            }
            .tyre-title-container {
                width: 100%;
                background-color: #f9fafb;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 14px;
                margin-bottom: 12px;
                .ui.tiny.basic.button {
                    height: 25px;
                    background-color: #fff !important;
                    p {
                        font-size: 11px;
                    }
                }
                .tyre-title {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    i.icon {
                        margin-top: -7px;
                    }
                    p {
                        font-size: 18px;
                        font-weight: bold;
                    }
                }
                .tyre-dbb-label {
                    p {
                        color: #21ba45;
                        font-size: 12.6px;
                        font-weight: bold;
                        cursor: pointer;
                    }
                }
            }
            .tyre-action {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                p {
                    font-size: 12.6px;
                    strong {
                        margin-right: 12px;
                    }
                    small {
                        margin-left: 3px;
                        color: #767676;
                        font-size: 9px;
                    }
                }
                .tyre-container {
                    height: 27px;
                    border: 1px solid #e2e2e3;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    justify-content: flex-end;
                    i.icon {
                        font-size: 12px;
                    }
                    .status-dropdown {
                        border-right: none;
                        i.icon {
                            font-size: 12px;
                            &.tiny {
                                font-size: 8px;
                            }
                            &.size-m {
                                margin-top: -12px;
                            }
                        }
                        .menu {
                            display: none;
                            box-shadow: none;
                            border: none !important;
                        }
                        .visible.transition {
                            margin-top: 2px;
                            div {
                                display: flex;
                                border: 1px solid #e2e2e3;
                                background: #fff;
                                box-shadow: none;
                                i.icon {
                                    margin-right: 0;
                                }
                                &:first-child {
                                    border-top-right-radius: 4px;
                                    border-top-left-radius: 4px;
                                }
                                &:last-child {
                                    border-bottom-right-radius: 4px;
                                    border-bottom-left-radius: 4px;
                                }
                            }
                        }
                    }
                    .action-dropdown {
                        border-right: none;
                        i.icon {
                            margin-top: -8px;
                            font-size: 14px;
                        }
                        .menu {
                            display: none;
                            box-shadow: none;
                            border: none !important;
                        }
                        .visible.transition {
                            margin-top: 2px;
                            width: fit-content;
                            box-shadow: none !important;
                             :hover {
                                background-color: #fafafa;
                            }
                            div {
                                border: 1px solid #e2e2e3;
                                background-color: #fff;
                                width: 100%;
                                display: flex;
                                justify-content: left;
                                align-items: center;
                                box-shadow: none;
                                right: 170px;
                                height: 29px;
                                font-weight: bold;
                                i.icon {
                                    margin: 10px 10px 10px 0;
                                }
                                &:first-child {
                                    border-top-right-radius: 4px;
                                    border-top-left-radius: 4px;
                                }
                                &:last-child {
                                    border-bottom-right-radius: 4px;
                                    border-bottom-left-radius: 4px;
                                }
                            }
                        }
                    }
                    i.icon {
                        margin: 0px;
                        &.pointer {
                            cursor: pointer;
                        }
                    }
                    div {
                        height: 100%;
                        border-left: 1px solid #e2e2e3;
                        width: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:first-child {
                            border-left: none;
                        }
                    }
                }
            }
        }
    }
    i.icon {
        margin: 0px;
        &.pointer {
            cursor: pointer;
        }
    }
}