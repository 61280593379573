.AppointmentDetails {
    &-AdvisedCriticalItemsHistory {
        &-AdvisedCriticalItemsHistoryActions {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            gap: 8px;
            p {
                font-size: 12.6px;
                strong {
                    margin-right: 12px;
                }
                small {
                    margin-left: 3px;
                    color: #767676;
                    font-size: 9px;
                }
            }
            .status-container {
                height: 25px;
                border: 1px solid #e2e2e3;
                border-radius: 4px;
                display: flex;
                align-items: center;
                width: fit-content;
                justify-content: flex-end;
                .status-dropdown {
                    border-right: none;
                    i.icon.size-m {
                        font-size: 12px;
                        margin-top: -12px;
                    }
                    .menu {
                        display: none;
                        box-shadow: none;
                        border: none !important;
                    }
                    .visible.transition {
                        margin-top: 2px;
                        div {
                            display: flex;
                            border: 1px solid #e2e2e3;
                            background: #fff;
                            box-shadow: none;
                            i.icon {
                                margin-right: 0;
                            }
                            &:first-child {
                                border-top-right-radius: 4px;
                                border-top-left-radius: 4px;
                            }
                            &:last-child {
                                border-bottom-right-radius: 4px;
                                border-bottom-left-radius: 4px;
                            }
                        }
                    }
                }
                .action-dropdown {
                    border-right: none;
                    i.icon {
                        height: auto;
                        margin: 0;
                    }
                    .menu {
                        display: none;
                        box-shadow: none;
                        border: none !important;
                    }
                    .visible.transition {
                        margin-top: 2px;
                        width: fit-content;
                        box-shadow: none !important;
                        :hover {
                            background-color: #fafafa;
                        }
                        div {
                            border: 1px solid #e2e2e3;
                            background-color: #fff;
                            width: 100%;
                            display: flex;
                            justify-content: left;
                            align-items: center;
                            box-shadow: none;
                            right: 170px;
                            height: 29px;
                            font-weight: bold;
                            i.icon {
                                margin: 10px 10px 10px 0;
                            }
                            &:first-child {
                                border-top-right-radius: 4px;
                                border-top-left-radius: 4px;
                            }
                            &:last-child {
                                border-bottom-right-radius: 4px;
                                border-bottom-left-radius: 4px;
                            }
                        }
                    }
                }
                i.icon {
                    margin: 0px;
                    &.pointer {
                        cursor: pointer;
                    }
                }
                div {
                    height: 100%;
                    border-left: 1px solid #e2e2e3;
                    width: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:first-child {
                        border-left: none;
                    }
                }
                svg {
                    margin: 0;
                    height: auto;
                }
            }
        }
    }
}